import { get, post, downloadFile } from '../request';

// 查询医生审批列表
export const findOnboardList = (params) => post(`/api/v1/admin/home/findOnboardList`, params)

// 医生审批
export const processOnboard = (params) => post(`/api/v1/admin/home/processOnboard`, params)

// 医生详情
export const getOnboardDetails = (params) => get(`/api/v1/admin/home/getOnboardDetails/${params}`)
