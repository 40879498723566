import { get, post, downloadFile } from '../request';

// 查询医生列表
export const findDoctorList = (params) => post(`/api/v1/admin/home/findDoctorList`, params)

// 添加医生
export const addDoctor = (params) => post(`/api/v1/admin/home/addDoctor`, params)

// 修改医生
export const updateDoctor = (params) => post(`/api/v1/admin/home/updateDoctor`, params)

// 修改医生密码
export const updatePassword = (params) => post(`/api/v1/admin/home/updatePassword`, params)

//删除医生
export const deleteDoctor = (params) => get(`/api/v1/admin/home/deleteDoctor/${params}`)

//重置医生DID
export const resetDoctorDID = (params) => get(`/api/v1/admin/home/resetDoctorDID/${params}`)

// 文件上传
export const upload = (params) => post(`/api/v1/sto/file/upload`, params)

//文件下载
export const download = (params) => downloadFile(`/api/v1/sto/file/download`, params,)    