import { get, post, downloadFile } from '../request';

//列表
export const completedMedicalRecords = (params) => post(`/api/v1/doctor/home/completedMedicalRecords`, params)

//详情
export const getIdCheckUp = (params) => get(`/api/v1/doctor/home/getIdCheckUp/${params}`)

export const diagnosisExportCSV = (params) => post(`/api/v1/doctor/home/diagnosisExportCSV`,params)

export const diagnosisWithArgsExportCSV = (params) => post(`/api/v1/doctor/home/diagnosisWithArgsExportCSV`,params)
