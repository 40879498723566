import {get, post} from '../request';

export const findInsuranceDependentList = (params) => post(`/api/v1/admin/home/findInsuranceDependentList`,params)

//添加
export const addInsuranceDependent = (params) => post(`/api/v1/admin/home/addInsuranceDependent`,params)

//编辑
export const updateInsuranceDependent = (params) => post(`/api/v1/admin/home/updateInsuranceDependent`,params)

//删除
export const deleteInsuranceDependent = (params) => get(`/api/v1/admin/home/deleteInsuranceDependent/${params}`)
