import {get, post} from '../request';

export const findPolicyList = (params) => post(`/api/v1/admin/home/findPolicyList`,params)

//模糊搜索
export const findInsuranceListByName = (params) => get(`/api/v1/admin/home/findInsuranceListByName/${params}`)

//添加
export const addPolicy = (params) => post(`/api/v1/admin/home/addPolicy`,params)

//修改
export const updatePolicy = (params) => post(`/api/v1/admin/home/updatePolicy`,params)

//删除
export const deletePolicy = (params) => get(`/api/v1/admin/home/deletePolicy/${params}`)
