import { get, post, downloadFile } from '../request';

// 查询医生年内月报
export const reportMonthListFromSystem = (params) => post(`/api/v1/report/month/reportMonthListFromSystem`, params)

// 医生列表
export const getDoctorInfoList = (params) => post(`/api/v1/report/month/getDoctorInfoList`, params)

// 当月诊断记录
export const getCheckUpMonthList = (params) => post(`/api/v1/report/month/getCheckUpMonthList`, params)

// 审核月报
export const reportMonthAuditFromSystem = (params) => post(`/api/v1/report/month/reportMonthAuditFromSystem`, params)

// 医生列表
// export const getDoctorIdList = (params) => post(`/api/v1/report/month/getDoctorIdList`, params)

// 下载月报CSV
export const downloadRePortMonthFromDoctor = (doctorId, fromMonth) => get(`/api/v1/report/month/downloadRePortMonthFromDoctor/${doctorId}/${fromMonth}`)