import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from './utils/config.js'
import * as api from './utils/api/index.js'
import debounce from './utils/debounce.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss';

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.use(config)
Vue.prototype.$debounce = debounce

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
